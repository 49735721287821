<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  watch: {
    '$i18n.locale'(newLocale) {
      document.documentElement.setAttribute('lang', newLocale);
    }
  },
  mounted() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
  }
}
</script>

<style scoped>

</style>