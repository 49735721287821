export default [
  {
    path: '/',
    name: 'WAV',
    component: () => import('../components/WAV.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../components/SignUp.vue'),
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: () => import('../components/Workspace.vue'),
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: () => import('../components/ChangePassword.vue'),
    props: (route) => ({token: route.query.token}),
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('../components/ForgotPassword.vue'),
  },
  {
    path: '/confirm_email/',
    name: 'ConfirmMail',
    component: () => import('../components/ConfirmMail.vue'),
    props: true,
  },
  {
    path: '/profile_settings',
    name: 'ProfileSettings',
    component: () => import('../components/ProfileSettings.vue'),
  },
  {
    path: '/privacy_policy',
    name: 'Privacy',
    component: () => import('../components/commons/Privacy.vue'),
  },
  {
    path: '/terms_and_conditions',
    name: 'Conditions',
    component: () => import('../components/commons/Conditions.vue'),
  },
  {
    path: '/check_order',
    name: 'CheckOrder',
    component: () => import('../components/CheckOrder.vue'),
    props: (route) => ({orderId: route.query.orderId}),
  },
];
